import React, { useEffect, useState } from "react";
import { PostCard } from "../components/postCard";
import cx from "classnames";
import { Link } from "gatsby";

export const BlogIndexPosts = ({ data, blogPosts, blogCategories, currentBlogCategoryId, numPages, currentPage }) => {
    let currentPath = "/" + data.slug.current;
    if (currentBlogCategoryId) {
        const blogCategory = blogCategories.find(
            (category) => category.id === currentBlogCategoryId
        );
        currentPath += `/${blogCategory.slug?.current}`;
    }

    let nextPage = currentPage + 1;
    let prevPage = currentPage - 1;
    if (prevPage === 1) prevPage = "";
    if (prevPage === 0) prevPage = null;

    if (nextPage >= numPages + 1) nextPage = null;
    let counter = 0;
    let numArray = [];
    while(counter < numPages) {
       numArray.push(counter);
       counter++;
    }

    const [fullUrl, setFullUrl] = useState(null);

    useEffect(() => {
        setFullUrl(window.location.pathname)
    });

    return (
  <section className="section pt-8">
    <div className="blog__indexPostsContainer">
      <h2 className="font-serif lowercase text-xl mb-5 px-3 lg:px-0">{data.postsTitle}</h2>

        <div className="relative">
            <div className="flex flex-col lg:grid lg:grid-cols-4 w-full">
                {blogPosts.map((post) => (
                <PostCard
                    key={post.id}
                    className="h-full"
                    post={post}
                    layout={post.previewLayout.title}
                />
                ))}
            </div>
            <div className="absolute w-px top-0 right-0 h-full bg-offWhite z-10"></div>
        </div>

        <div className="flex justify-end w-full pr-5 mb-16">
            <div className="flex justify-end">
                {numArray.map((num) => {
                    if (fullUrl !== null && fullUrl !== undefined) {
                        if (num === 0 ) {
                            if (fullUrl === currentPath || fullUrl === `${currentPath}/`) {
                                return (
                                    <Link className="mr-4" to={ currentPath }>{num + 1}</Link>
                                )
                            } else {
                                return (
                                    <Link className="mr-4 text-gray" to={ currentPath }>{num + 1}</Link>
                                )
                            }
                        } else {
                            if (fullUrl === `${currentPath}/${num + 1}`) {
                                return (
                                    <Link className="mr-4" to={ currentPath }>{num + 1}</Link>
                                )
                            } else {
                                return (
                                    <Link className="mr-4 text-gray" to={`${currentPath}/${num + 1}`}>{num + 1}</Link>
                                )
                            }
                        }
                    } else {
                        return null
                    }
                 })}
            </div>
          {prevPage !== null && (
            <div className={cx(nextPage && "", "")}>
              <Link
                className="font-sans lowercase text-xs"
                to={`${currentPath}/${prevPage}`}
              >
                Previous
                <span className="hidden md:inline">&nbsp;Page</span>
              </Link>
            </div>
          )}
          {nextPage !== null && (
            <div className={cx(prevPage && "", "")}>
              <Link
                className="font-sans lowercase text-xs"
                to={`${currentPath}/${nextPage}`}
              >
                Next
                <span className="hidden md:inline">&nbsp;Page</span>
              </Link>
            </div>
          )}
        </div>
    </div>
  </section>
);
}