import { graphql, useStaticQuery } from "gatsby";

const BlogIndexQuery = () => {
  const { sanityBlogIndex } = useStaticQuery(graphql`
    query BlogIndexQuery {
      sanityBlogIndex(_id: { eq: "blogIndex" }) {
        page {
          content {
            blogTitle
            slug {
              current
            }
          }
        }
      }
    }
  `);
  return sanityBlogIndex.page.content;
};
export default BlogIndexQuery;