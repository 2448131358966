import React from "react";
import { BlogIndexFeatured } from "../blog/blogIndexFeatured";
import { BlogIndexHero } from "../blog/blogIndexHero";
import { BlogIndexPosts } from "../blog/blogIndexPosts";
import { BlogHero } from "../blog/blogHero";

import { Seo } from "../components/seo";

const BlogList = ({ pageContext, location }) => {
  const {
    numPages,
    blogPosts,
    blogCategories,
    currentPage,
    currentBlogCategoryId,
    page: { meta, content },
  } = pageContext;

  return (
    <>
      <Seo metaInfo={meta} location={location} />
      {/* {content.heroPost && <BlogIndexHero data={content} />} */}
      <BlogHero data={content} />
      <BlogIndexFeatured data={content} />
      <BlogIndexPosts
        data={content}
        currentPage={currentPage}
        blogCategories={blogCategories}
        blogPosts={blogPosts}
        currentBlogCategoryId={currentBlogCategoryId}
        numPages={numPages}
      />
    </>
  );
};

export default BlogList;