import React from "react";
import { SanityImage } from "./sanityImage.js";
import PortableText from "./portableText.js";
import cx from "classnames";
// import Arrow from "../icons/arrow.js";
import { Link } from "gatsby";
import BlogIndexQuery from "../static-queries/blogIndexQuery.js";

const hoverCard = (e) => {
  let targetEl = e.target;
  let parentEl;
  if (targetEl.classList.contains(".card__container")) {
    parentEl = targetEl;
  } else {
    parentEl = targetEl.closest(".card__container");
  }

  if (parentEl) {
    let cardImage = parentEl.querySelector(".preview__image");
    let cardTitle = parentEl.querySelector(".preview__title");
    if (cardImage) {
      cardImage.style.opacity = "0.9";
    }

    if (cardTitle) {
      cardTitle.style.fontFamily = "Historical Italic";
    }
  }
};

const exitCard = (e) => {
  let targetEl = e.target;
  let parentEl;
  if (targetEl.classList.contains(".card__container")) {
    parentEl = targetEl;
  } else {
    parentEl = targetEl.closest(".card__container");
  }
  if (parentEl) {
    let cardImage = parentEl.querySelector(".preview__image");
    let cardTitle = parentEl.querySelector(".preview__title");
    if (cardImage) {
      cardImage.style.opacity = "1";
    }
    if (cardTitle) {
      cardTitle.style.fontFamily = "Historical";
    }
  }
};

export const PostCard = ({ post, className, layout }) => (
  <Link
    onMouseEnter={hoverCard}
    onMouseLeave={exitCard}
    to={`/${BlogIndexQuery().slug.current}/posts/${post.slug.current}`}
    className={cx(
      className,
      "pb-6 md:pb-16",
      layout === "Landscape" && "col-span-2",
      layout === "Portrait" && "col-span-1"
    )}
  >
    <div className="card__container  border-r border-dashed border-gold h-full">
      <div className="border-t md:border-b border-dashed border-gold p-2.5 md:p-4">
        {layout === "Landscape" ? (
          <SanityImage
            className={cx("preview__image pointer-events-none")}
            alt={post.headline}
            image={post.mainImage}
            gatsbyImageDataArgs={{ width: "100%" }}
          />
        ) : (
          <SanityImage
            className={cx("preview__image pointer-events-none")}
            alt={post.headline}
            image={post.mainImage}
            gatsbyImageDataArgs={{ width: "100%" }}
          />
        )}
      </div>
      <div className="col-span-8 flex-1 px-3 pb-3 pt-1 lg:pt-3 m">
        <div className="post__contentContainer max-w-lg">
          <div className="preview__category font-serif text-gold lowercase pb-3">
            {post.category.title}
          </div>
          {/* <div className="text-lg font-semibold">{post.title}</div> */}
          <PortableText
            className="preview__title font-historical text-lg flex justify-start pb-2 tl"
            blocks={post.title}
          />
          <PortableText
            className="post__preview-text flex font-historical md:mb-4 md:mt-2 text-xs"
            blocks={post.excerpt}
          />
        </div>
        <div className="flex flex-row justify-between">
          {/* <div className="text-4xs md:text-3xs uppercase">
                        BY {post.author.name}
                    </div> */}
          <div>{/* <Arrow /> */}</div>
        </div>
      </div>
    </div>
  </Link>
);
