import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostCard } from "../components/postCard";
import { Link } from "gatsby";
import { SanityImage } from "../components/sanityImage";
import cx from "classnames";
import PortableText from "../components/portableText";


import Next_Arrow from "../icons/next_arrow";
import Prev_Arrow from "../icons/prev_arrow";


function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
          <Next_Arrow />
      </div>
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
        className={className}
        onClick={onClick}
      >
          <Prev_Arrow />
      </div>
    );
  }

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
};

export const BlogIndexFeatured = ({ data }) => (
  <section className="section">
    <div className="blog__featured">
      {/* <h2 className="small-title bordered mb-5 md:mb-10">
        {data.featuredTitle}
      </h2> */}
      <h2 className="font-serif lowercase text-xl mb-5">
            {data.featuredTitle}
        </h2>
      {/* <h3 className="mb-16 lg:mb-20 text-2xl lg:text-5xl font-semibold max-w-4xl">
        {data.featuredCopy}
      </h3> */}

      <Slider {...settings}>
      {data.featuredPosts.map((post) => (


        <Link
            key={post.id}
            to={`/${data.slug.current}/posts/${post.slug.current}`}
            className="flex flex-col"
        >





        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2">
                <SanityImage
                    className={cx("w-full h-full")}
                    alt={post.headline}
                    image={post.mainImage}
                    gatsbyImageDataArgs={{ aspectRatio: 1.11853088481 }}
                />
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center">
                <div className="flex flex-col justify-center items-center w-full md:w-1/2 lg:w-3/4 h-full">
                    <div className="blog__featuredPostCategory text-gold font-sans lowercase pb-3 pt-8 md:pt-0">{post.category.title}</div>
                    <PortableText
                        className="font-historical text-center text-2xl"
                        blocks={post.title}
                    />
                    <div className="blog__featuredPostHeadline font-historical text-center font-2xs pt-6">{post.headline}</div>
                    {/* <PortableText
                        className="font-serif text-center font-2xs pt-6"
                        blocks={post.excerpt}
                    /> */}
                    <button className="read__more font-serif lowercase text-xs mt-10 hover:bg-darkGray transition-all duration-200 ease-in-out">Read Story</button>
                </div>
            </div>
        </div>
    </Link>




        ))}
      </Slider>

    </div>
  </section>
);
