import React from "react";

import { PostCard } from "../components/postCard";
import { Link } from "gatsby";
import { SanityImage } from "../components/sanityImage";
import cx from "classnames";
import PortableText from "../components/portableText";


export const BlogHero = ({ data }) => {


    return (
        <section className="section">
            <div className="relative block">
             <SanityImage
                className={cx("blog__index-image")}
                alt={"Celaya Tequila Journal Post"}
                image={data.hero_image}
                gatsbyImageDataArgs={{ width: "100vw" }}
            />
            <div className="flex justify-center w-full h-full text-center">
                <PortableText
                  className="font-historical md:mb-4 md:mt-2 text-base text-white w-full md:w-1/2 text-center  pt-44 pb-24 px-8 md:px-0"
                  blocks={data.excerpt}
                />
            </div>
            </div>
        </section>
    )
};